import {CSSProperties} from 'react';
import {ShouldRenderVarsFnParams, CustomCssVarsFnParams} from '../../../baseCustomCssVars';
import {
  AddToCartButtonPlacement,
  Alignments,
  ButtonContentType,
  ButtonWidthType,
  ImagePlacements,
  ImagePositions,
  NamePriceLayout,
  PriceAndDiscountLayouts,
  RibbonPlacementId,
  RibbonType,
  VerticalAlignments,
} from '../../../types/galleryTypes';

export enum alignItems {
  Center = 'center',
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
}

export enum JustifyContent {
  Center = 'center',
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  SpaceBetween = 'space-between',
  Normal = 'normal',
}

/* istanbul ignore file */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const productItemCustomCssVars = ({styleParams: styles, isMobile, isRTL}: CustomCssVarsFnParams) => {
  const isHorizontalLayout =
    styles.strings[isMobile ? 'mobile:gallery_imagePlacement' : 'gallery_imagePlacement'].value ===
    ImagePlacements.HORIZONTAL;
  const productItemVerticalAlignment = styles.strings.gallery_verticalAlignment.value;
  const getProductItemFlexDirection = (isOddIndex): CSSProperties['flexDirection'] => {
    const imagePosition = styles.strings[isMobile ? 'mobile:gallery_imagePosition' : 'gallery_imagePosition'].value;
    const shouldAlternateImagePosition =
      styles.booleans[isMobile ? 'mobile:gallery_alternateImagePosition' : 'gallery_alternateImagePosition'];
    const row = 'row';
    const rowReverse = 'row-reverse';

    if (!isHorizontalLayout) {
      return 'column';
    }
    if (isOddIndex && shouldAlternateImagePosition) {
      if (isRTL) {
        return imagePosition === ImagePositions.LEFT ? row : rowReverse;
      }

      return imagePosition === ImagePositions.RIGHT ? row : rowReverse;
    }

    if (isRTL) {
      return imagePosition === ImagePositions.RIGHT ? row : rowReverse;
    }

    return imagePosition === ImagePositions.LEFT ? row : rowReverse;
  };

  const getProductItemAlignItems = (): CSSProperties['alignItems'] => {
    const alignment = styles.strings.gallery_alignment.value;
    const flexStart = alignItems.FlexStart;
    const flexEnd = alignItems.FlexEnd;
    const center = alignItems.Center;

    switch (alignment) {
      case Alignments.LEFT:
        return isRTL ? flexEnd : flexStart;
      case Alignments.RIGHT:
        return isRTL ? flexStart : flexEnd;
      case Alignments.CENTER:
      default:
        return center;
    }
  };

  const getNamePriceLayoutAlignItems = (): CSSProperties['alignItems'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return alignItems.FlexStart;
      case NamePriceLayout.NameAbovePrice:
        return getProductItemAlignItems();
    }
  };

  const getNamePriceLayoutFlexDirection = (): CSSProperties['flexDirection'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return 'row';
      case NamePriceLayout.NameAbovePrice:
        return 'column';
    }
  };

  const getNamePriceLayoutJustifyContent = (): CSSProperties['justifyContent'] => {
    const alignment = styles.strings.gallery_alignment.value;
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    if (namePriceLayoutType === NamePriceLayout.NameAbovePrice) {
      return JustifyContent.Normal;
    }

    switch (alignment) {
      case Alignments.CENTER:
        return JustifyContent.Center;
      case Alignments.LEFT:
      case Alignments.RIGHT:
        return JustifyContent.SpaceBetween;
    }
  };

  const getNamePriceLayoutFlexGap = (): CSSProperties['gap'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;
    const gap = styles.numbers.gallery_nameAndPriceGap;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return gap;
      case NamePriceLayout.NameAbovePrice:
        return 0;
    }
  };

  const getNameTextAlign = (): CSSProperties['textAlign'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return 'left';
      case NamePriceLayout.NameAbovePrice:
        return 'inherit';
    }
  };

  const getNameLineHeight = (): CSSProperties['lineHeight'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return '1.5em';
      case NamePriceLayout.NameAbovePrice:
        return undefined;
    }
  };

  const getPricesMarginTop = (): CSSProperties['marginTop'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return '0';
      case NamePriceLayout.NameAbovePrice:
        return '4px';
    }
  };

  const getPricesMarginTopMobile = (): CSSProperties['marginTop'] => {
    const namePriceLayoutType = styles.numbers.gallery_namePriceLayout;

    switch (namePriceLayoutType) {
      case NamePriceLayout.NamePriceSideBySide:
        return '0';
      case NamePriceLayout.NameAbovePrice:
        return '3px';
    }
  };

  const getProductItemJustifyContent = (): CSSProperties['justifyContent'] => {
    if (!isHorizontalLayout) {
      return JustifyContent.SpaceBetween;
    }

    switch (productItemVerticalAlignment) {
      case VerticalAlignments.TOP:
        return JustifyContent.FlexStart;
      case VerticalAlignments.BOTTOM:
        return JustifyContent.FlexEnd;
      case VerticalAlignments.STRETCH:
        return JustifyContent.SpaceBetween;
      default:
        return JustifyContent.Center;
    }
  };
  const getPriceAndDiscountWrapBehavior = (): CSSProperties['flexWrap'] => {
    const namePriceLayout = styles.numbers.gallery_namePriceLayout;
    switch (namePriceLayout) {
      case NamePriceLayout.NamePriceSideBySide:
        return 'nowrap';
      case NamePriceLayout.NameAbovePrice:
      default:
        return 'wrap';
    }
  };

  const getPriceAndDiscountLayoutJustifyContent = (): CSSProperties['justifyContent'] => {
    const isPriceAndDiscountRowReverse =
      styles.numbers.gallery_priceAndDiscountLayout ===
      PriceAndDiscountLayouts.HORIZONTAL_REGULAR_PRICE_BEFORE_DISCOUNT;
    const productItemAlignment = getProductItemAlignItems();
    if (productItemAlignment === JustifyContent.Center) {
      return JustifyContent.Center;
    }
    if (isPriceAndDiscountRowReverse) {
      return productItemAlignment === JustifyContent.FlexStart ? JustifyContent.FlexEnd : JustifyContent.FlexStart;
    } else {
      return productItemAlignment;
    }
  };

  const getPriceAndDiscountLayout = (): CSSProperties['flexDirection'] => {
    switch (styles.numbers.gallery_priceAndDiscountLayout) {
      case PriceAndDiscountLayouts.HORIZONTAL_REGULAR_PRICE_AFTER_DISCOUNT:
        return 'row';
      case PriceAndDiscountLayouts.HORIZONTAL_REGULAR_PRICE_BEFORE_DISCOUNT:
        return 'row-reverse';
      case PriceAndDiscountLayouts.VERTICAL_REGULAR_PRICE_AFTER_DISCOUNT:
        return 'column';
      default:
        return 'column-reverse';
    }
  };
  const getPriceAndDiscountVerticalAlignment = (): CSSProperties['alignItems'] => {
    const isVertical =
      styles.numbers.gallery_priceAndDiscountLayout ===
        PriceAndDiscountLayouts.VERTICAL_REGULAR_PRICE_BEFORE_DISCOUNT ||
      styles.numbers.gallery_priceAndDiscountLayout === PriceAndDiscountLayouts.VERTICAL_REGULAR_PRICE_AFTER_DISCOUNT;
    return isVertical ? getProductItemAlignItems() : 'baseline';
  };

  const horizontalLayoutImageWidth = `${
    styles.numbers[isMobile ? 'mobile:gallery_imageWidth' : 'gallery_imageWidth']
  }%`;

  const horizontalLayoutSpacing = `${
    styles.numbers[isMobile ? 'mobile:gallery_imageAndInfoSpacing' : 'gallery_imageAndInfoSpacing']
  }px`;

  const ribbonRectangleType = styles.numbers.gallery_ribbonType === RibbonType.RECTANGLE;
  const showProductDetails =
    styles.booleans.gallery_showProductName ||
    styles.booleans.gallery_showDividers ||
    styles.booleans.gallery_showPrice;
  const showAddToCartButton =
    styles.booleans[isMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'];
  const verticalNotImageContainerPaddingTop = showProductDetails || showAddToCartButton ? '16px' : '0px';
  return {
    priceAndDiscountWrapBehavior: getPriceAndDiscountWrapBehavior(),
    priceAndDiscountLayoutFlexDirection: getPriceAndDiscountLayout(),
    priceAndDiscountVerticalAlignment: getPriceAndDiscountVerticalAlignment(),
    priceAndDiscountHorizontalAlignment: getPriceAndDiscountLayoutJustifyContent(),
    productItemFlexDirection: getProductItemFlexDirection(false),
    productItemOddIndexFlexDirection: getProductItemFlexDirection(true),
    productItemImageWidth: isHorizontalLayout ? horizontalLayoutImageWidth : 'revert',
    productItemNotImageContainerWidth: isHorizontalLayout
      ? `calc(100% - ${horizontalLayoutImageWidth} - ${horizontalLayoutSpacing} + 0px)`
      : 'revert',
    productItemImageAndInfoSpacing: isHorizontalLayout ? horizontalLayoutSpacing : 'revert',
    productItemNotImageContainerPaddingTop: isHorizontalLayout
      ? `${styles.numbers[isMobile ? 'mobile:gallery_productMargin' : 'gallery_productMargin']}px`
      : verticalNotImageContainerPaddingTop,
    productItemVerticalAlignment: getProductItemJustifyContent(),
    productItemHorizontalAlignment: getProductItemAlignItems(),
    productItemDetailsLinkFlexGrow: isHorizontalLayout ? 0 : 1,
    addToCartButtonShowOnHoverOpacity: styles.booleans.gallery_addToCartButtonShowOnHover ? '0' : 'inherit',
    addToCartButtonShowOnHoverOpacityOnHover: styles.booleans.gallery_addToCartButtonShowOnHover ? '1' : 'inherit',
    ribbonPadding: ribbonRectangleType ? '2px 12px' : 'inherit',
    ribbonBackground: ribbonRectangleType ? styles.colors.gallery_ribbonBackground : 'inherit',
    ribbonBorder: ribbonRectangleType
      ? `${styles.numbers.gallery_ribbonBorderWidth}px solid ${styles.colors.gallery_ribbonBorderColor}`
      : 'none',
    addToCartButtonWidth:
      styles.strings.gallery_addToCartButtonWidth.value === ButtonWidthType.FIT ? 'fit-content' : '100%',
    disabledOptionsOpacity: styles.colors.gallery_quantityAndOptionsTextColor ? 0.6 : 1,
    namePriceLayoutFlexDirection: getNamePriceLayoutFlexDirection(),
    namePriceLayoutAlignItems: getNamePriceLayoutAlignItems(),
    namePriceLayoutJustifyContent: getNamePriceLayoutJustifyContent(),
    namePriceLayoutFlexGap: getNamePriceLayoutFlexGap(),
    nameTextAlign: getNameTextAlign(),
    nameLineHeight: getNameLineHeight(),
    pricesMarginTop: getPricesMarginTop(),
    pricesMarginTopMobile: getPricesMarginTopMobile(),
  };
};

export const productItemShouldRenderVars = ({booleans, numbers, isMobile}: ShouldRenderVarsFnParams) => {
  const showAddToCartButton = booleans[isMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'];

  return {
    showAddToCartInProductInfo:
      showAddToCartButton && numbers.gallery_addToCartButtonPlacement === AddToCartButtonPlacement.ProductInfo,
    showAddToCartOnImage:
      showAddToCartButton && numbers.gallery_addToCartButtonPlacement === AddToCartButtonPlacement.OnImage,
    showAddToCartTextButton: numbers.gallery_addToCartButtonContentType === ButtonContentType.Text,
    showAddToCartIconAndTextButton: numbers.gallery_addToCartButtonContentType === ButtonContentType.IconAndText,
    showAddToCartIconButton: numbers.gallery_addToCartButtonContentType === ButtonContentType.Icon,
    shouldShowRibbonOnImage:
      booleans.gallery_showRibbon && numbers.gallery_ribbonPlacement === RibbonPlacementId.OnImage,
    shouldShowRibbonOnProductInfo:
      booleans.gallery_showRibbon && numbers.gallery_ribbonPlacement === RibbonPlacementId.ProductInfo,
    showProductDetails: booleans.gallery_showProductName || booleans.gallery_showDividers || booleans.gallery_showPrice,
    notShowProductName: !booleans.gallery_showProductName,
    notShowAddToCartButtonOrIconOnlyButton:
      !showAddToCartButton || numbers.gallery_addToCartButtonContentType === ButtonContentType.Icon,
    showTextualAddToCartButton:
      showAddToCartButton && numbers.gallery_addToCartButtonContentType !== ButtonContentType.Icon,
    showDropdownOptions: !booleans.gallery_showColorOptionsOnly,
    showDivider:
      booleans.gallery_showDividers && numbers.gallery_namePriceLayout !== NamePriceLayout.NamePriceSideBySide,
  };
};
